<div class="tableContainer" [ngClass]="{ loading: loading }">
  <div *ngIf="search && searchTerm">
    <mat-form-field appearance="outline">
      <mat-label>{{ "TABLES.GENERAL.FILTER" | translate }}</mat-label>
      <input [formControl]="searchTerm" matInput placeholder="{{ 'TABLES.GENERAL.FILTER' | translate }}..." />
    </mat-form-field>
  </div>

  <ng-container *ngIf="loading; else table">
    <mat-spinner></mat-spinner>
  </ng-container>
  <mat-paginator
    *ngIf="pageSizes"
    [pageSizeOptions]="pageSizes"
    (page)="onPageSizeChange($event)"
    [ngClass]="{ hidden: dataSource.data.length < pageSizes[0] }"
  ></mat-paginator>
</div>

<ng-template #table (window:resize)="showMobileTable()">
  <div style="overflow-x: auto">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
      <!-- mobile table -->
      <ng-container *ngIf="mobileTable" [matColumnDef]="headers[0].visualName">
        <td mat-cell *matCellDef="let element">
          <div class="row w-100 py-3">
            <div class="col-3" [ngClass]="header.columnStyles?.class" *ngFor="let header of headers">
              <p *ngIf="!header.buttons">
                <span style="font-size: 10px; display: block">{{ "TABLES." + id + "." + header.visualName | translate }}</span>
                <span>
                  <!-- SWITCHING CONTENT STYLES -->
                  <ng-container *ngIf="header.date && header.mappedTo">
                    {{ element.claimStateId === 5 || !element.claimStateId ? (element[header.mappedTo] | localDate: "d MMMM y, HH:mm") : "-" }}
                  </ng-container>

                  <ng-container *ngIf="header.currency && header.mappedTo">
                    {{ element[header.mappedTo] | currency: "EUR" : "symbol" : ".2-2" : "nl" }}
                  </ng-container>

                  <ng-container *ngIf="header.status && header.mappedTo">
                    {{ "TABLES.REPLACEMENT_OVERVIEW." + element[header.mappedTo] | uppercase | translate }}
                  </ng-container>

                  <ng-container *ngIf="header.resolver">
                    {{ header.resolver(element) }}
                  </ng-container>

                  <ng-container *ngIf="header.mappedTo && !header.date && header.mappedTo && !header.currency && !header.status">
                    {{ element[header.mappedTo!] }}
                  </ng-container>

                  <ng-container *ngIf="header.link">
                    <a [routerLink]="makeFullLink(header.link, element)">{{ header.visualName | titlecase }}</a>
                  </ng-container>

                  <ng-container *ngIf="header.click">
                    <button mat-icon-button [color]="header.click.color" (click)="header.click.clickHandler(element)">
                      <mat-icon [matTooltip]="header.tooltip ?? ''">{{ header.click.icon }}</mat-icon>
                    </button>
                  </ng-container>
                </span>
              </p>
              <ng-container class="d-flex justify-content-between pt-3" *ngIf="header.buttons">
                <ng-container [ngTemplateOutlet]="buttonsTemplate" [ngTemplateOutletContext]="{ header: header, element: element }"></ng-container>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- desktop table -->
      <ng-container *ngIf="!mobileTable">
        <ng-container *ngFor="let header of headers" [matColumnDef]="header.visualName">
          <ng-container *ngIf="header.sorting?.sort">
            <th
              [style]="
                header.columnStyles?.width
                  ? 'width: ' + header.columnStyles?.width + ';'
                  : '' + header.columnStyles?.align
                    ? 'text-align: ' + header.columnStyles?.align + ';'
                    : ''
              "
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              disableClear
            >
              <span>{{ "TABLES." + id + "." + header.visualName | translate }}</span>
            </th>
          </ng-container>
          <ng-container *ngIf="!header.sorting?.sort">
            <th
              [style]="
                header.columnStyles?.width
                  ? 'width: ' + header.columnStyles?.width + ';'
                  : '' + header.columnStyles?.align
                    ? 'text-align: ' + header.columnStyles?.align + ';'
                    : ''
              "
              mat-header-cell
              *matHeaderCellDef
            >
              <span>{{ "TABLES." + id + "." + header.visualName | translate }}</span>
            </th>
          </ng-container>
          <td
            class="templates"
            style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
            [style]="'text-align:' + header.columnStyles?.align"
            class="pe-4"
            mat-cell
            *matCellDef="let element"
          >
            <ng-container *ngIf="header.currency && header.mappedTo; else publishedToggle">
              {{ element[header.mappedTo] | currency: "EUR" : "symbol" : ".2-2" : "nl" }}
            </ng-container>

            <ng-template #publishedToggle>
              <ng-container *ngIf="header.visualName === 'PUBLISHED_TOGGLE' && header.click; else dated">
                <button
                  mat-icon-button
                  [color]="element['active'] ? 'primary' : 'warn'"
                  (click)="header.click.clickHandler(element)"
                  [matTooltip]="'COMPONENTS.ALL_QUESTIONNAIRES.' + (element.active ? 'DEACTIVATE' : 'PUBLISH') + '_QUESTIONNAIRE.TOOLTIP' | translate"
                  [attr.aria-label]="'COMPONENTS.ALL_QUESTIONNAIRES.' + (element.active ? 'DEACTIVATE' : 'PUBLISH') + '_QUESTIONNAIRE.TOOLTIP' | translate"
                >
                  <mat-icon>{{ element["active"] ? "check_circle" : "cancel" }}</mat-icon>
                </button>
              </ng-container>
            </ng-template>

            <ng-template #dated>
              <ng-container *ngIf="header.date && header.mappedTo; else status">
                {{ element.claimStateId === 5 || !element.claimStateId ? (element[header.mappedTo] | localDate: "d MMMM y, HH:mm") : "-" }}
                <!-- {{ element.deliveryID ? (element[header.mappedTo] | localDate : "d MMMM y, HH:mm") : "-" }} -->
              </ng-container>
            </ng-template>

            <ng-template #status>
              <ng-container *ngIf="header.status && header.mappedTo; else mappedTo">
                {{ "TABLES.REPLACEMENT_OVERVIEW." + element[header.mappedTo] | uppercase | translate }}
              </ng-container>
            </ng-template>

            <ng-template #mappedTo>
              <ng-container *ngIf="header.mappedTo; else resolvedProperty">
                {{ element[header.mappedTo] }}
              </ng-container>
            </ng-template>

            <ng-template #resolvedProperty>
              <ng-container *ngIf="header.resolver; else link">
                {{ header.resolver(element) }}
              </ng-container>
            </ng-template>

            <ng-template #link>
              <ng-container *ngIf="header.link; else button">
                <a [routerLink]="makeFullLink(header.link, element)">{{ header.visualName | titlecase }}</a>
              </ng-container>
            </ng-template>

            <ng-template #button>
              <ng-container *ngIf="header.click; else normalButton">
                <button
                  mat-icon-button
                  [color]="header.click.color"
                  (click)="header.click.clickHandler(element)"
                  [attr.aria-label]="header.visualName"
                  [matTooltip]="header.tooltip ?? ''"
                >
                  <mat-icon>{{ header.click.icon }}</mat-icon>
                </button>
              </ng-container>
            </ng-template>

            <ng-template #normalButton>
              <ng-container *ngIf="header.regularButton; else actions">
                <button mat-stroked-button [color]="header.regularButton.color" (click)="header.regularButton.clickHandler(element)">
                  <mat-icon [matTooltip]="header.tooltip ?? ''">{{ header.regularButton.icon }}</mat-icon>
                </button>
              </ng-container>
            </ng-template>

            <ng-template #actions>
              <ng-container
                *ngIf="header.visualName === 'actions'; else customTemplate"
                [ngTemplateOutlet]="buttonsTemplate"
                [ngTemplateOutletContext]="{ header: header, element: element }"
              ></ng-container>
            </ng-template>

            <ng-template #customTemplate>
              <ng-container [ngTemplateOutlet]="header.customTemplate ?? null" [ngTemplateOutletContext]="{ data: element }"> </ng-container>
            </ng-template>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="visibleHeaders" class="visible"></tr>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: visibleHeaders"></tr>

      <tr *matNoDataRow>
        <td colspan="100%">{{ "TABLES.GENERAL.NO_DATA" | translate }}</td>
      </tr>
    </table>
  </div>
</ng-template>

<ng-template #buttonsTemplate let-header="header" let-element="element">
  <ng-container *ngFor="let actionButton of header.buttons">
    <ng-container *ngIf="actionButton.key === 'schedule' && element.orderId && element.claimStateId === 4">
      <button mat-stroked-button [color]="actionButton.color" (click)="actionButton.clickHandler(element)">
        <mat-icon [matTooltip]="header.tooltip ?? ''">{{ actionButton.icon }}</mat-icon> {{ actionButton.label | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="actionButton.key === 'deliver' && element.orderId && element.claimStateId === 5">
      <button mat-stroked-button [color]="actionButton.color" (click)="actionButton.clickHandler(element)">
        <mat-icon [matTooltip]="header.tooltip ?? ''">{{ actionButton.icon }}</mat-icon> {{ actionButton.label | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="actionButton.key === 'order' && !element.orderId">
      <button mat-stroked-button [color]="actionButton.color" (click)="actionButton.clickHandler(element)">
        <mat-icon [matTooltip]="header.tooltip ?? ''">{{ actionButton.icon }}</mat-icon> {{ actionButton.label | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="actionButton.key === 'reject-order' && element.orderId && element.claimStateId === 4">
      <button mat-stroked-button [color]="actionButton.color" (click)="actionButton.clickHandler(element)">
        <mat-icon [matTooltip]="header.tooltip ?? ''">{{ actionButton.icon }}</mat-icon> {{ actionButton.label | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="actionButton.key === 'reject-delivery' && element.orderId && element.claimStateId === 5">
      <button mat-stroked-button [color]="actionButton.color" (click)="actionButton.clickHandler(element)">
        <mat-icon [matTooltip]="header.tooltip ?? ''">{{ actionButton.icon }}</mat-icon> {{ actionButton.label | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="actionButton.key === 'reject-claim' && element.cooldownPeriod">
      <button mat-stroked-button [color]="actionButton.color" (click)="actionButton.clickHandler(element)">
        <mat-icon [matTooltip]="header.tooltip ?? ''">{{ actionButton.icon }}</mat-icon> {{ actionButton.label | translate }}
      </button>
    </ng-container>
  </ng-container>
</ng-template>
