<div
  class="tile px-1"
  [ngClass]="{ blue: statistic?.status === 'TOTAL' || statistic?.status === 'FUTURE', green: statistic?.status === 'CURRENT', grey: statistic?.status === 'PAST' }"
>
  <ng-container *ngIf="statistic; else loading">
    <div class="d-flex flex-column">
      <!--
        HEADER (state + period)
      -->
      <ng-container *ngIf="statistic.status === 'TOTAL'">
        <div class="d-flex flex-row justify-content-between p-1">
          <div>
            <b>{{ "COMPONENTS.DASHBOARD_STATISTICS.STATISTIC_STATUS.TOTAL" | translate }}</b>
          </div>
        </div>
        <br />
      </ng-container>

      <ng-container *ngIf="statistic.status !== 'TOTAL'">
        <div class="d-flex flex-row justify-content-between p-1">
          <div *ngIf="statistic.selection.areaAction === null">
            <b>{{ "COMPONENTS.DASHBOARD_STATISTICS.PERIOD" | translate }}</b>
          </div>
          <div *ngIf="statistic.selection.areaAction !== null">
            <b>{{ "COMPONENTS.DASHBOARD_STATISTICS.AREA_ACTION" | translate }}</b>
          </div>
          <div>{{ "COMPONENTS.DASHBOARD_STATISTICS.STATISTIC_STATUS." + statistic.status | translate }}</div>
        </div>
        <div *ngIf="statistic.selection.timeRange?.start">
          {{ statistic.selection.timeRange!.start! | localDate: "mediumDate" }} - {{ statistic.selection.timeRange!.end! | localDate: "mediumDate" }}
        </div>
        <div *ngIf="statistic.selection.areaAction !== null">
          {{ statistic.selection.areaAction.area + " | " + statistic.selection.areaAction.zipCodeFrom + " - " + statistic.selection.areaAction.zipCodeTo }}
        </div>
      </ng-container>

      <hr />

      <!--
        STATES (Graph + values)
      -->
      <app-chart
        [data]="{
          labels: [
            statusChartData.new.percentage + '% ' + statusChartData.new.label,
            statusChartData.active.percentage + '% ' + statusChartData.active.label,
            statusChartData.done.percentage + '% ' + statusChartData.done.label,
          ],
          datasets: [{ data: [statusChartData.new.count, statusChartData.active.count, statusChartData.done.count], backgroundColor: ['#0d6efd', '#00a99d', '#4d4d4d'] }],
        }"
      ></app-chart>
      <div class="d-flex flex-row justify-content-around p-1" style="text-align: center">
        <div>
          <div>
            <b>{{ statusChartData.new.count }}</b>
          </div>
          <div>{{ statusChartData.new.label }}</div>
        </div>
        <div>
          <div>
            <b>{{ statusChartData.active.count }}</b>
          </div>
          <div>{{ statusChartData.active.label }}</div>
        </div>
        <div>
          <div>
            <b>{{ statusChartData.done.count }}</b>
          </div>
          <div>{{ statusChartData.done.label }}</div>
        </div>
      </div>

      <!--
        COSTS
      -->
      <div class="p-1">
        <div class="d-flex flex-row justify-content-between">
          <div>{{ "COMPONENTS.DASHBOARD_STATISTICS.TOTAL" | translate }}:</div>
          <div>
            {{ statistic.totalPrice | currency: "EUR" : "symbol" : ".2-2" : "nl" }}
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <div>{{ "COMPONENTS.DASHBOARD_STATISTICS.AVERAGE" | translate }}:</div>
          <div>
            {{ statistic.averagePrice | currency: "EUR" : "symbol" : ".2-2" : "nl" }}
          </div>
        </div>
      </div>

      <div class="p-1">
        <div>
          <b> {{ "COMPONENTS.DASHBOARD_STATISTICS.COSTS" | translate }} </b>
        </div>
        <hr />
        <div *ngFor="let rc of reportCategoriesData?.items" class="row">
          <div class="col-5">
            <b>
              {{ rc.name }}
            </b>
          </div>
          <div class="col-3" style="text-align: end">{{ rc.costValues?.[0]?.count }}x</div>
          <div class="col-4" style="text-align: end">{{ rc.costValues?.[0]?.totalPrice | currency: "EUR" : "symbol" : ".2-2" : "nl" }}</div>
        </div>

        <hr *ngIf="(reportCategoriesData?.selects?.length ?? 0) > 0" />
        <div *ngFor="let rc of reportCategoriesData?.selects" class="d-flex flex-column gap-1 justify-content-between">
          <div>
            <b>
              {{ rc.name }}
            </b>
          </div>
          <div>
            <div *ngFor="let select of rc.selectValues" class="row">
              <div class="col-5">
                <b>
                  {{ select.value }}
                </b>
              </div>
              <div class="col-3" style="text-align: end">{{ select.answeredCount }}x</div>
              <div class="col-4" style="text-align: end">{{ select.cost | currency: "EUR" : "symbol" : ".2-2" : "nl" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="h-100 d-flex justify-content-center align-items-center py-4">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
