import { inject, Injectable } from "@angular/core";
import { AreaAction } from "../classes/flow/AreaActions/AreaAction";
import { CostPerCategoryStatisticItem, FormattedReportCategory, ReportCategoryStatisticItem, Statistic, StatisticGQL } from "../classes/flow/Questionnaire/Statistic";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root",
})
export class StatisticService {
  private graphql: GraphQLService;

  public cachedAreaActions: AreaAction[];

  constructor() {
    this.graphql = inject(GraphQLService);

    this.cachedAreaActions = [];
  }

  public async getStatistic(params?: { startDate?: Date; endDate?: Date; areaActionID?: number; municipalityID?: number }, areaAction?: AreaAction): Promise<Statistic> {
    console.log(params?.municipalityID);
    let paramString = "";
    if (params && Object.values(params).length > 0) {
      paramString += " (";
      for (const [key, val] of Object.entries(params)) {
        if (val) {
          if (paramString.length > 3) paramString += " , ";

          paramString += `${key}: ${val instanceof Date ? this.graphql.hasuraArrayObjectCleaner(val) : val}`;
        }
      }
      paramString += ") ";
    }

    const res = await this.graphql.query(`
      query {
        statistics ${paramString}  {
          value {
            count
            averageTimeInDays
            averageTimeInHours
            totalPrice
            averagePrice
            specialtyStatistics
            stateStatistics
            coachStatistics
            reportCategoryStatistics
            costPerCategoryStatistics
          }
          messages {
            message
          }
        }
        }
      `);

    return this.mapToObject(res.data.statistics.value[0], params, areaAction);
  }

  public mapToObject(value: StatisticGQL, params?: { startDate?: Date; endDate?: Date; areaActionID?: number }, areaAction?: AreaAction): Statistic {
    /**
     * set status
     */
    const currentDate = new Date();
    let status: "TOTAL" | "FUTURE" | "CURRENT" | "PAST" = "TOTAL";
    if (params) {
      if (params.endDate && currentDate.getTime() > params.endDate.getTime()) {
        status = "PAST";
      } else if (params.startDate && currentDate.getTime() > params.startDate.getTime()) {
        status = "CURRENT";
      } else {
        status = "FUTURE";
      }

      if (params.areaActionID !== undefined && areaAction !== undefined) {
        if (currentDate.getTime() > areaAction.dateTo.getTime()) {
          status = "PAST";
        } else if (currentDate.getTime() > areaAction.dateFrom.getTime()) {
          status = "CURRENT";
        } else {
          status = "FUTURE";
        }
      }
    }

    /**
     * format report categories
     */
    const parsedReportCategoryStatitics: ReportCategoryStatisticItem[] = value.reportCategoryStatistics ? JSON.parse(value.reportCategoryStatistics) : [];

    const parsedCostPerCategoryStatitics: CostPerCategoryStatisticItem[] = value.costPerCategoryStatistics ? JSON.parse(value.costPerCategoryStatistics) : [];

    const formatted: FormattedReportCategory[] = [];

    for (const element of parsedReportCategoryStatitics) {
      if (!formatted.some((frc) => frc.id === element.reportCategoryID)) {
        formatted.push({
          id: element.reportCategoryID,
          name: element.reportCategoryName,
          description: element.reportCategoryDescription,
          type: element.bs5Value ? "select" : "cost",
          selectValues: element.bs5Value
            ? [
              {
                answeredCount: element.answeredCount,
                value: element.bs5Value,
                cost:
                    parsedCostPerCategoryStatitics.find((costItem) => costItem.reportCategoryID === element.reportCategoryID && costItem.bs5Value === element.bs5Value)
                      ?.cost ?? 0,
              },
            ]
            : undefined,
          costValues: !element.bs5Value ? [{ count: element.answeredCount, totalPrice: element.totalPrice!, percentageOfTotal: element.percentageOfTotal! }] : undefined,
        });
      } else {
        if (element.bs5Value) {
          formatted
            .find((frc) => frc.id === element.reportCategoryID)
            ?.selectValues?.push({
              answeredCount: element.answeredCount,
              value: element.bs5Value,
              cost:
                parsedCostPerCategoryStatitics.find((costItem) => costItem.reportCategoryID === element.reportCategoryID && costItem.bs5Value === element.bs5Value)?.cost ?? 0,
            });
        } else {
          formatted
            .find((frc) => frc.id === element.reportCategoryID)
            ?.costValues?.push({ count: element.answeredCount, totalPrice: element.totalPrice!, percentageOfTotal: element.percentageOfTotal! });
        }
      }
    }

    /**
     * return
     */
    return {
      status: status,
      count: value.count,
      averageTimeInDays: value.averageTimeInDays,
      averageTimeInHours: value.averageTimeInHours,
      totalPrice: value.totalPrice,
      averagePrice: value.averagePrice,
      specialtyStatistics: value.specialtyStatistics ? JSON.parse(value.specialtyStatistics) : [],
      stateStatistics: value.stateStatistics ? JSON.parse(value.stateStatistics) : [],
      coachStatistics: value.coachStatistics ? JSON.parse(value.coachStatistics) : [],
      reportCategoryStatistics: parsedReportCategoryStatitics,
      formatedReportCategories: formatted,
      costPerCategoryStatistics: parsedCostPerCategoryStatitics,
      selection: {
        areaActionID: params?.areaActionID ?? null,
        areaAction: areaAction ?? null,
        timeRange: areaAction
          ? { start: areaAction.dateFrom, end: areaAction.dateTo }
          : params?.endDate && params.startDate
            ? { start: params.startDate, end: params.endDate }
            : null,
      },
    };
  }
}
