import { Specialty } from "../../Questionnaire/Specialty";
import { Changes } from "../../base/Changes";
import { Role } from "../Session";
import { User } from "./User";

export class Coordinator extends User {
  public id: number;
  public specialties: Specialty[] = [];
  public municipalities: { id: number; name: string }[] = [];

  public constructor({
    userId,
    roles = [],
    id,
    userEmail,
    firstName,
    lastName,
    accessStartingDate,
    accessEndDate,
    changes,
    specialties,
    municipalities,
  }: {
    userId?: number;
    id: number;
    userEmail: string;
    roles?: Role[];
    firstName?: string;
    lastName?: string;
    accessStartingDate?: Date;
    accessEndDate?: Date;
    changes?: Changes;
    specialties?: Specialty[];
    municipalities?: { id: number; name: string }[];
  }) {
    super({ firstName, lastName, email: userEmail, id: userId, roles, accessStartingDate: accessStartingDate, accessEndDate: accessEndDate, changes: changes });
    this.id = id;
    this.specialties = specialties ?? [];
    this.municipalities = municipalities ?? [];
  }
}
