import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { ENVIRONMENT } from "../../environments/environment";

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(`${logLevel}: ${message}`);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: ENVIRONMENT.CLIENT_ID,
      authority: ENVIRONMENT.AUTHORITY,
      knownAuthorities: [ENVIRONMENT.KNOWN_AUTHORITY],
      redirectUri: "/",
      postLogoutRedirectUri: "/loggedout",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set(`${ENVIRONMENT.API_URL}graphqlpublic/`, null);
  protectedResourceMap.set(`${ENVIRONMENT.API_URL}graphql/endpoints/qrcode`, null);
  protectedResourceMap.set(`${ENVIRONMENT.API_URL}graphql/endpoints/validateQR`, [ENVIRONMENT.CLIENT_ID]);
  protectedResourceMap.set(`${ENVIRONMENT.API_URL}graphql/`, [ENVIRONMENT.CLIENT_ID]);
  protectedResourceMap.set(`${ENVIRONMENT.API_URL}content/`, [ENVIRONMENT.CLIENT_ID]);
  protectedResourceMap.set(`${ENVIRONMENT.API_URL}action/`, [ENVIRONMENT.CLIENT_ID]);
  protectedResourceMap.set(`${ENVIRONMENT.API_URL}bookmarks`, [ENVIRONMENT.CLIENT_ID]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [""],
    },
    loginFailedRoute: "/login-failed",
  };
}
