<!-- li>.icon+.content>span.title+span.value <--- Emmet snipper for new row -->

<div class="energyConsult">
  <ul class="list-unstyled information">
    <li>
      <div class="icon">
        <mat-icon>checklist_rtl</mat-icon>
      </div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.STATUS" | translate }}</span>
        <span class="value d-flex flex-column gap-1">
          <span class="status">
            {{ "COMPONENTS.TILE.STATUSES." + energyConsult.state.name | translate }}
          </span>
          <app-state-progress-bar
            [flow]="[
              { name: 'PendingDeleted', notion: 'Error' },
              { name: 'Canceled', notion: 'Warning' },
              { name: 'Open' },
              { name: 'New' },
              { name: 'PendingReject', notion: 'Warning' },
              { name: 'Picked' },
              { name: 'Date' },
              { name: 'FilledIn' },
              { name: 'Done' },
              { name: 'Disapproved', notion: 'Warning' },
            ]"
            [currentState]="currentState"
          ></app-state-progress-bar>
        </span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>school</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.SPECIALTY" | translate }}</span>
        <span class="value">{{ energyConsult.specialty ? energyConsult.specialty.name : "" }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>hail</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.COACH" | translate }}</span>
        <span class="value">{{ displayCoachName() ? displayCoachName() : ("COMPONENTS.TILE.ABSENT_DATA" | translate) }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>fact_check</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.DATE_REQUESTED" | translate }}</span>
        <span class="value">{{ energyConsult.requestDate | localDate: "fullDate" }}</span>
      </div>
    </li>

    <li *ngIf="energyConsult.state.name === 'PendingDeleted'">
      <div class="icon"><mat-icon>auto_delete</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.DATE_REQUESTED_DELETION" | translate }}</span>
        <span class="value">{{ energyConsult.lastStateChangeDate! | localDate: "fullDate" }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>work_history</mat-icon></div>
      <div class="content">
        <span class="title">{{ energyConsult.appointmentDate ? ("COMPONENTS.TILE.DATE_PLANNED" | translate) : ("COMPONENTS.TILE.DATE_NOT_PLANNED" | translate) }}</span>
        <span class="value">{{
          energyConsult.appointmentDate ? (energyConsult.appointmentDate | localDate: "EEEE, d MMMM y, HH:mm") : ("COMPONENTS.TILE.ABSENT_DATA" | translate)
        }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>explore</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.LOCATION" | translate }}</span>
        <span class="value">{{ energyConsult.getLocation(applicationService) }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>description</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.EXTRA_MESSAGE" | translate }}</span>
        <span
          class="value"
          [innerText]="energyConsult.message !== 'null' && energyConsult.message.length ? energyConsult.message : ('COMPONENTS.TILE.EMPTY_EXTRA_MESSAGE' | translate)"
        ></span>
      </div>
    </li>

    <!-- <div class="col-8 data">
      <span class="info"> </span>
      <span *ngIf="energyConsult.message !== 'null' && this.inspecting === true" class="overflow-hidden">{{ energyConsult.message }}</span>
      <span *ngIf="energyConsult.message !== 'null' && this.inspecting !== true" class="text-truncate">{{ energyConsult.message }}</span>
      <span *ngIf="energyConsult.message === 'null' || energyConsult.message === ''" class="text-wrap"></span>
    </div> -->
    <li
      *ngIf="
        (energyConsult.isActive && energyConsultId) ||
        (includesModule('SHOW_CONTACT_INFO_ON_NEW_REQUESTS') &&
          (applicationService.session.activeRole.name === 'coach' || applicationService.session.activeRole.name === 'coordinator'))
      "
    >
      <div class="icon"><mat-icon>contact_page</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.CONTACT" | translate }}</span>
        <span class="value">{{ displayResidentName() ? displayResidentName() : "-" }}</span>
      </div>
    </li>

    <li
      *ngIf="
        (energyConsult.isActive && energyConsultId) ||
        (includesModule('SHOW_CONTACT_INFO_ON_NEW_REQUESTS') &&
          (applicationService.session.activeRole.name === 'coach' || applicationService.session.activeRole.name === 'coordinator'))
      "
    >
      <div class="icon"><mat-icon>local_phone</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.PHONE_NUMBER" | translate }}</span>
        <span class="value">{{ !energyConsult.resident?.phoneNumber ? "-" : energyConsult.resident?.phoneNumber }}</span>
      </div>
    </li>

    <li
      *ngIf="
        (energyConsult.isActive && energyConsultId) ||
        (includesModule('SHOW_CONTACT_INFO_ON_NEW_REQUESTS') &&
          (applicationService.session.activeRole.name === 'coach' || applicationService.session.activeRole.name === 'coordinator'))
      "
    >
      <div class="icon"><mat-icon>alternate_email</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.EMAIL" | translate }}</span>
        <span class="value text-break">{{ energyConsult.resident?.email ? energyConsult.resident?.email : "-" }}</span>
      </div>
    </li>

    <li *ngIf="energyConsult.ckdInfo && energyConsult.ckd && energyConsult.ckdInfo.length > 0 && applicationService.session.activeRole.name === 'coordinator'">
      <div class="icon"><mat-icon>move_up</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.CKD" | translate }}</span>
        <span class="value text-break">{{ energyConsult.ckdInfo }}</span>
      </div>
    </li>

    <ng-container *appModule="'AREA_ACTIONS'">
      <li *ngIf="applicationService.session.activeRole.name === 'coach' || applicationService.session.activeRole.name === 'coordinator'">
        <div class="icon"><mat-icon>campaign</mat-icon></div>
        <div class="content">
          <span class="title">{{ "COMPONENTS.TILE.AREA_ACTION" | translate }}</span>
          <ng-container *ngIf="energyConsult.areaActions && energyConsult.areaActions.length > 0; else noAreaActions">
            <div *ngFor="let action of energyConsult.areaActions; last as isLast" class="value">
              <div>{{ action.area }}</div>
              <div>{{ action.dateFrom | localDate }} - {{ action.dateTo | localDate }}</div>
              <div>{{ action.zipCodeFrom | postalcode }} - {{ action.zipCodeTo | postalcode }}</div>
              <mat-divider class="my-2" *ngIf="!isLast" />
            </div>
          </ng-container>
          <ng-template #noAreaActions>
            <span class="value">{{ "COMPONENTS.TILE.NO_AREA_ACTION" | translate }}</span>
          </ng-template>
        </div>
      </li>
    </ng-container>
  </ul>
  <div *ngIf="mapView && (energyConsult.state.name === 'New' || energyConsult.state.name === 'Picked' || energyConsult.state.name === 'Date')" class="border-top col p-1">
    <ng-container *ngIf="energyConsult.state.name === 'New' || energyConsult.state.name === 'Picked' || energyConsult.state.name === 'Date'">
      <ng-container *ngTemplateOutlet="changeCoach"></ng-container>
    </ng-container>
    <ng-container *ngIf="energyConsult.state.name === 'Picked' || energyConsult.state.name === 'Date'">
      <ng-container *ngTemplateOutlet="editDateTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="editDate"> </ng-container>
    </ng-container>
  </div>

  <div *ngIf="!checkAction(3)" class="actions border-top d-flex flex-wrap">
    <button
      *appIsRole="['coach', 'coordinator']"
      mat-flat-button
      class="regularButton"
      [routerLink]="[applicationService.session.activeRole.name === 'coach' ? '/content/coach/request/' : '/content/coordinator/request/', energyConsult.id]"
    >
      <mat-icon>chevron_right</mat-icon>
      {{ "COMPONENTS.TILE.REQUEST_OPEN" | translate }}
    </button>

    <ng-container *appIsRole="['coach', 'coordinator']">
      <button *ngIf="energyConsult.extraProperties?.note" [matTooltip]="'COMPONENTS.TILE.NOTES' | translate" mat-icon-button class="icon defaultIconButton" (click)="showNoteDialog()">
        <mat-icon >speaker_notes</mat-icon>
      </button>
    </ng-container>

    <button
      *ngIf="energyConsultCanBeCanceled"
      mat-icon-button
      class="discardButton"
      (click)="cancelRequestDialog(energyConsult.id)"
      [attr.aria-label]="'COMPONENTS.DASHBOARD.CANCEL' | translate"
      [matTooltip]="'COMPONENTS.DASHBOARD.CANCEL' | translate"
    >
      <mat-icon>do_not_disturb_on</mat-icon>
    </button>

    <button
      *ngIf="energyConsultCanBeDeleted"
      mat-icon-button
      class="deleteButton"
      (click)="deleteRequestDialog(energyConsult.id)"
      [matTooltip]="'COMPONENTS.DASHBOARD.DELETE' | translate"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <button
      *ngIf="(energyConsult.state.name === 'Done' || energyConsult.state.name === 'FilledIn') && applicationService.session.activeRole.name === 'resident'"
      mat-flat-button
      class="btn-block regularButton"
      [routerLink]="['/content/resident/report/', energyConsult.id]"
    >
      <mat-icon>wysiwyg</mat-icon>
      {{ "COMPONENTS.TILE.SEE_REPORT" | translate }}
    </button>

    <ng-container *appIsRole="['coach', 'coordinator', 'resident']">
      <button
        mat-icon-button
        class="icon defaultIconButton"
        [matTooltip]="'COMPONENTS.DASHBOARD.BOOKMARKS.TOOLTIP' | translate"
        (click)="toggleBookmark()"
        [disabled]="bookmarkService.status !== 'fetched'"
      >
        <mat-icon>{{ energyConsult.bookmarked ? "bookmark" : "bookmark_border" }}</mat-icon>
      </button>
    </ng-container>
  </div>
</div>

<ng-template #deleteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.TILE.REQUEST_DELETE.TITLE" | translate }}</h3>
        <p>
          {{ "COMPONENTS.TILE.REQUEST_DELETE.CONFIRM" | translate }}
          {{
            !(!checkAction(2) && energyConsult.canBeDeleted) && applicationService.session.activeRole.name === "resident"
              ? ("COMPONENTS.TILE.REQUEST_DELETE.EMAIL" | translate)
              : ""
          }}
        </p>
        <div class="d-flex gap-3 flex-wrap justify-content-center">
          <button mat-raised-button color="primary" class="m-0" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</button>
          <button mat-raised-button color="warn" class="m-0" (click)="deleteRequest(); dialogService.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancelDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.TILE.REQUEST_CANCEL.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.TILE.REQUEST_CANCEL.CONFIRM" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="warn" (click)="cancelRequest(); dialogService.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.NOTE.ROLE_NOTES" | translate }}</h3>
        <p [innerText]="data.note"></p>
      </div>
    </div>
  </div>
</ng-template>
<!-- TODO should be put into generic component and used in this and coach-energyconsult component -->
<ng-template #editDate>
  <div class="row actions" *ngIf="mapView">
    <div class="col" *ngIf="!isEditingDate; else editingDate">
      <button mat-flat-button class="regularButton primary my-1" (click)="setEditingDate(true)">
        <span>{{ "COMPONENTS.COACH_REQUEST.EDIT_DATE_TIME_BUTTON" | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #editDateTemplate>
  <div class="row actions" *ngIf="isEditingDate">
    <div class="col">
      <!-- list of material cards showing time slots -->
      <p *ngIf="energyConsult?.extraProperties?.preferredTimeSlots ? (energyConsult?.extraProperties?.preferredTimeSlots)!.length > 0 : false">
        {{ "COMPONENTS.COACH_REQUEST.RESIDENT_PREFERRED_APPOINTMENT" | translate }}
      </p>
      <div style="display: flex; flex-direction: row; overflow-x: auto; gap: 0.25rem; margin-bottom: 0.5rem">
        <mat-card *ngFor="let timeSlot of energyConsult?.extraProperties?.preferredTimeSlots; index as slotIndex" style="margin: 0.25rem">
          <mat-card-content>
            <div style="display: flex; flex-direction: row; min-width: max-content">
              <div style="display: flex; flex-direction: column; justify-content: space-between; margin-right: 0.5rem">
                <div>{{ timeSlot.startTime | localDate: "fullDate" }}</div>
                <div>
                  {{
                    ("GENERAL.FROM" | translate) +
                      " " +
                      timeSlot.startTime.toLocaleTimeString("en-GB").slice(0, 5) +
                      " " +
                      ("GENERAL.UNTILL" | translate) +
                      " " +
                      timeSlot.endTime.toLocaleTimeString("en-GB").slice(0, 5)
                  }}
                </div>
              </div>
              <button mat-icon-button (click)="fillInPreferredTimeSlot(slotIndex)" class="d-flex align-items-center">
                <mat-icon style="color: #666">arrow_right_alt</mat-icon>
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-75 mt-2">
              <mat-label>{{ "COMPONENTS.COACH_REQUEST.DATE" | translate }}</mat-label>
              <input matInput [min]="energyConsult.minimalAppointmentDate" [matDatepicker]="datepicker" readonly [max]="maxDate" formControlName="date" />
              <app-error [control]="formGroup.controls.date"></app-error>
              <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker></mat-datepicker>
            </mat-form-field>
          </div>
          <br />
        </div>
        <div class="row">
          <div class="col-12">
            <app-custom-timepicker #time givenFormControlName="time" [givenFormGroup]="formGroup"></app-custom-timepicker>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #editingDate>
  <div class="row actions">
    <div class="col">
      <button mat-flat-button class="regularButton primary my-1" [disabled]="formGroup.invalid" (click)="saveDate()">
        <span>{{ "FORMS.SAVE" | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #changeCoach>
  <div class="actions row" *ngIf="energyConsult?.canCoachBeChanged">
    <div class="col">
      <button mat-flat-button class="regularButton primary my-1" (click)="this.changeCoachClickHandler(changeCoachDialog)">
        <span>{{ "COMPONENTS.COACH_REQUEST.CHANGE_COACH.NAME" | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #changeCoachDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md touchScreenAccessability">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.DESCRIPTION" | translate }}</p>

        <div class="d-block">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.LABEL" | translate }}</mat-label>
            <input
              type="text"
              #val
              placeholder="{{ 'COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.PLACEHOLDER' | translate }}"
              matInput
              [formControl]="changeCoachControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="changeDisplayCoachName" #coachInput>
              <mat-option class="touchScreenAccessability" *ngFor="let coach of filteredOptions | async" [value]="coach">
                {{ coach.firstName }} {{ coach.lastName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="val.value.length === 0" (click)="assignCoach(changeCoachControl.value)">
          {{ "COMPONENTS.DIALOG.SUBMIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- Till here -->
