import { Injectable } from "@angular/core";
import { MutationResult } from "apollo-angular";
import { ENVIRONMENT } from "../../environments/environment";
import { AreaAction } from "../classes/flow/AreaActions/AreaAction";
import { Specialty } from "../classes/flow/Questionnaire/Specialty";
import { Coordinates, EnergyConsult, TimeSlot } from "../classes/flow/request/EnergyConsult";
import { RequestState } from "../classes/flow/request/RequestStates";
import { Coach } from "../classes/flow/session/impl/Coach";
import { Resident } from "../classes/flow/session/impl/Resident";
import { User } from "../classes/flow/session/impl/User";
import { convertDate } from "../helpers/convertDate";
import { ApplicationService } from "./application.service";
import { AreaActionService } from "./area-action.service";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root",
})
export class EnergyConsultService {
  public constructor(
    private readonly applicationService: ApplicationService,
    private readonly graphqlService: GraphQLService,
    private readonly areaActionService: AreaActionService,
  ) {}

  public async changeJson(energyConsult: EnergyConsult, addedJsonProperty: string) {
    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${energyConsult.id}
          set: {
            extraProperties: ${addedJsonProperty}
            changes: ${this.graphqlService.formatChangesObject(energyConsult)}

          }
        }) {
          value {
            id
          }
          messages {
            message
          }
        }
      }`,
    );
  }
  /**
   * Saves a property of an energyConsult
   * @param energyConsult The given energyConsult to update
   * @param key The property of the energyConsult to update
   * @returns The result that include the id
   */
  public async save(energyConsult: EnergyConsult, key?: string) {
    let energyConsultData: any = {
      appointmentDate: energyConsult.appointmentDate,
      coachId: energyConsult.coach ? energyConsult.coach.id : null,
      houseNumber: energyConsult.houseNumber,
      houseNumberSuffix: energyConsult.houseNumberSuffix,
      message: energyConsult.message,
      postalCode: energyConsult.postalCode,
      rejectReason: energyConsult.rejectReason,
      requestStateId: await this.getEnergyConsultStateId(energyConsult),
      changes: energyConsult.changes,
    };

    if (key) energyConsultData = { [key]: energyConsultData[key] };

    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${energyConsult.id}
          set: {
            ${energyConsultData.appointmentDate ? `appointmentDate: "${convertDate(energyConsultData.appointmentDate)}",` : ""}
            ${energyConsultData.coachId ? `coachId: ${energyConsultData.coachId},` : ""}
            ${energyConsultData.houseNumber ? `houseNumber: ${energyConsultData.houseNumber},` : ""}
            ${energyConsultData.houseNumberSuffix ? `houseNumberSuffix: "${energyConsultData.houseNumberSuffix}",` : ""}
            ${energyConsultData.message ? `message: ${this.graphqlService.parseObject(energyConsult.message)},` : ""}
            ${energyConsultData.postalCode ? `postalCode: "${energyConsultData.postalCode}",` : ""}
            ${energyConsultData.rejectReason ? `rejectReason: ${this.graphqlService.parseObject(energyConsultData.rejectReason)},` : ""}
            ${energyConsultData.requestStateId ? `requestStateId: ${energyConsultData.requestStateId},` : ""}
            changes: ${this.graphqlService.formatChangesObject(energyConsult)}
          }
        }) {
          value {
            id
          }
          messages {
            message
          }
        }
      }`,
    );
  }

  public async saveAfterEdit(EC: any) {
    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${EC.id}
          set: {
            ${EC.firstName ? `firstName: "${EC.firstName}",` : ""}
            ${EC.lastName ? `lastName: "${EC.lastName}",` : ""}
            ${EC.email ? `email: "${EC.email}",` : ""}
            ${EC.phoneNumber ? `phoneNumber: "${EC.phoneNumber}",` : ""}
            ${EC.address.postalCode ? `postalCode: "${EC.address.postalCode}",` : ""}
            ${EC.address.houseNumber ? `houseNumber: ${EC.address.houseNumber},` : ""}
            ${typeof EC.address.houseNumberSuffix === "string" ? `houseNumberSuffix: "${EC.address.houseNumberSuffix}",` : ""}
            ${
              EC.streetName && EC.town
                ? 'extraProperties: "' + JSON.stringify({ streetname: EC.streetName, addressdetails: { town: EC.town } }).replaceAll('"', '\\"') + '"'
                : ""
            }
            changes: ${this.graphqlService.formatChangesObject(EC)}
          }
        }) {
          value {
            id
          }
          messages {
            message
          }
        }
      }`,
    );
  }

  /**
   *
   * @param id
   * @returns streetname of the request id that was given
   */
  public async getStreetNameOfConsult(id: number) {
    return await this.graphqlService.query(`
    query {
      energyConsults {
        value (where: {id: {eq: ${id}}}) {
          id
          streetName
        }
        messages {
          message
        }
      }
    }`);
  }
  /**
   * Gets data of particular energyConsults
   * @param where The where-clause to specify the right energyConsult(s)
   * @returns The result with the data of the energyConsult
   */
  private async getEnergyConsultData(where = ""): Promise<MutationResult<any>> {
    return await this.graphqlService.query(
      `query {
              energyConsults {
                value ${where} {
                  id
                  ckd
                  ckdInfo
                  bookmarked
                  changes {
                    lastChange {
                      userId
                      time
                    }
                    fullDetails{
                      key
                      value {
                        userId
                        time
                      }
                    }
                  }
                  requestState {
                    id
                    name
                  }
                  specialtyId
                  residentId
                  resident {
                    id
                    userId
                    firstName
                    lastName
                    email
                    phoneNumber
                  }
                  requestDate
                  ${this.applicationService.session.activeRole?.name == "coach" || this.applicationService.session.activeRole?.name == "coordinator" ? "rejectReason" : ""}
                  ${this.applicationService.session.activeRole?.name == "coordinator" ? "disapprovalReason" : ""}
                  postalCode
                  email
                  phoneNumber
                  firstName
                  lastName
                  message
                  houseNumber
                  houseNumberSuffix
                  appointmentDate
                  statusLastChanged
                  coachId
                  coach {
                    id
                    firstName
                    lastName
                    email
                  }
                  specialty {
                    id
                    name
                    description
                    order
                  }
                  extraProperties
                  ${
                    this.applicationService.session.activeRole.name === "coordinator" || this.applicationService.session.activeRole.name === "coach"
                      ? "ffwdgetmailhistoryactionid"
                      : ""
                  }
                }
                messages {
                  message
                }
              }
            }`,
    );
  }

  /**
   * Gets energyConsult objects of the given data
   * @param energyConsultData data that include the energyConsult data
   * @returns An array of energyConsult objects
   */
  private async getEnergyConsults(energyConsultData: MutationResult<any>): Promise<EnergyConsult[]> {
    const allAreaActions = ENVIRONMENT.MODULES.includes("AREA_ACTIONS") ? await this.areaActionService.all(false, true) : [];

    return energyConsultData.data["energyConsults"].value.map((data: any): EnergyConsult => {
      let coach;

      if (data.coach) {
        coach = new Coach({
          id: data.coach.id,
          userEmail: data.coach.email,
          firstName: data.coach.firstName,
          lastName: data.coach.lastName,
        });
      }

      const specialty: Specialty = {
        id: data.specialty.id,
        name: data.specialty.name,
        description: data.specialty.description,
        order: data.specialty.order,
      };

      const resident = new Resident({
        id: data.resident.id,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
      });

      const areaActions: AreaAction[] = allAreaActions.filter((areaAction) => {
        return this.areaActionService.zipCodeInArea(data.postalCode, areaAction);
      });

      // Parsing extraProperties
      const extraProperties: {
        preferredTimeSlots?: TimeSlot[];
        houseCoordinates?: Coordinates;
        postalcodeBounds?: Coordinates[];
        postalcodeCoordinates?: Coordinates;
        streetname?: string;
        addressdetails?: {
          town?: string;
          municipality?: string;
          province?: string;
        };
        note?: string;
      } = { preferredTimeSlots: [] };

      if (data.extraProperties) {
        const parsedExtraProperties = JSON.parse(data.extraProperties);

        const knownKeys: Array<keyof typeof extraProperties> = [
          "preferredTimeSlots",
          "houseCoordinates",
          "postalcodeBounds",
          "postalcodeCoordinates",
          "streetname",
          "addressdetails",
          "note",
        ];

        for (const key in parsedExtraProperties) {
          if (knownKeys.includes(key as any)) {
            if (key === "preferredTimeSlots") {
              extraProperties[key] = parsedExtraProperties[key].map((timeSlot: any) => ({
                startTime: new Date(timeSlot.startTime),
                endTime: new Date(timeSlot.endTime),
              }));
            } else {
              extraProperties[key as keyof typeof extraProperties] = parsedExtraProperties[key];
            }
          }
        }
      }
      return new EnergyConsult({
        id: data.id,
        message: data.message,
        postalCode: data.postalCode,
        houseNumber: data.houseNumber,
        requestDate: new Date(data.requestDate),
        state: {
          id: data.requestState.id,
          name: data.requestState.name,
        },
        specialty: specialty,
        resident: resident,
        houseNumberSuffix: data.houseNumberSuffix,
        lastStateChangeDate: new Date(data.statusLastChanged),
        rejectReason: data.rejectReason,
        coach: coach,
        appointmentDate: data.appointmentDate ? new Date(data.appointmentDate) : undefined,
        disapprovalReason: data.disapprovalReason,
        changes: this.graphqlService.createChangesObject(data.changes),
        extraProperties: extraProperties,
        areaActions: areaActions,
        ckd: data.ckd,
        ckdInfo: data.ckdInfo,
        mailHistoryId: data.ffwdgetmailhistoryactionid,
        bookmarked: data.bookmarked ?? false,
      });
    });
  }

  public async ckdEC(id: number): Promise<MutationResult<any>> {
    return await this.graphqlService.query(
      `mutation {
      ckd (energyConsultId: ${id}) {
        value {
          requestIDOld
          requestIDNew
        }
        messages {
          message
        }
      }
    }`,
    );
  }

  /**
   *
   * @param requests
   * @returns requests filters by allowed specialties
   */
  public filterRequestsByAllowedSpecialties(requests: EnergyConsult[]) {
    return requests.filter((request) => ENVIRONMENT.ALLOWED_SPECIALTIES.includes(request.specialty.id));
  }

  /**
   * Gets energyConsults based on the given user
   * @param user The user
   * @returns An array with energyConsults
   */
  public async loadByUser(user: User): Promise<EnergyConsult[]> {
    const role: string = user instanceof Coach ? "coach" : "resident";
    const energyConsults = await this.getEnergyConsultData(role === "coordinator" ? "" : `(where: {${role === "coach" ? "coachId" : "residentId"}: {eq: ${user.id}}})`);
    return await this.getEnergyConsults(energyConsults);
  }

  public async loadInspectingAsCoord(id: string, role: string): Promise<EnergyConsult[]> {
    let requests;
    role === "coach" || role === "coordinator"
      ? (requests = await this.getEnergyConsultData(`(where: {coach_id: {_eq: "${id}"}})`))
      : (requests = await this.getEnergyConsultData(`(where: {resident_id: {_eq: "${id}"}})`));
    // const requests = await this.getRequestData(`(where: {id: {_eq: "${id}"}})`);
    return await this.getEnergyConsults(requests);
  }

  /**
   * Gets the accepted energyConsults of a given coach
   * @param coach The coach
   * @returns An array with energyConsults
   */
  public async loadAcceptedByCoach(coach: Coach): Promise<EnergyConsult[]> {
    const energyConsults = await this.getEnergyConsultData(`(where: {coachId: {eq: ${coach.id}}})`);
    return await this.getEnergyConsults(energyConsults);
  }

  /**
   * Gets a request based on the given id
   * @param id The id of the needed request
   * @returns A request
   */
  public async loadById(id: number): Promise<EnergyConsult> {
    const requests = await this.getEnergyConsultData(`(where: {id: {eq: ${id}}})`);
    return (await this.getEnergyConsults(requests))[0];
  }

  /**
   * Get all requests
   * @returns An array with requests
   */
  public async load(year: number | null): Promise<EnergyConsult[]> {
    let where: string | undefined;
    if (year !== null) {
      where = `(where: { year: { eq: "${year}" } })`;
    }
    const requests = await this.getEnergyConsultData(where);
    return await this.getEnergyConsults(requests);
  }

  /**
   * Get all requests
   * @returns An array with requests
   */
  public async sendPDFToResident(energyConsultId: number) {
    const res = await this.graphqlService.query(`
      mutation {
        sendReportAsPDF(energyConsultId: ${energyConsultId}) {
          value {
            email
          }
          messages {
            message
          }
        }
      }`);
    if (!res.data["sendReportAsPDF"]?.value?.email) {
      throw new Error("Something went wrong");
    }
  }

  /**
   * Sends a PDF request to the coach. If the request failed, there will be thrown an error.
   * @param requestId The id of the request
   */
  public async sendPdfRequestToCoach(requestId: number) {
    const res = await this.graphqlService.query(`mutation MyMutation { send_report_as_pdf_to_coach(input: {request_id: "${requestId}"}) { email_address } }`);
    if (!res.data["send_report_as_pdf_to_coach"]?.email_address) {
      throw new Error("Something went wrong");
    }
  }

  public async checkDoneStatus(requestId: string) {
    const res = await this.graphqlService.query(
      `query{
        requests(where: {id: {_eq: "${requestId}"}})  {
          state
          changes {
            lastChange {
              userId
              time
            }
            fullDetails {
              key
              value {
                userId
                time
              }
            }
        }
        messages{
          message
        }
      }
      `,
    );
    return res.data["requests"][0].state;
  }

  /**
   * Sets the state of the energyConsult to done
   * @param energyConsult The id of the energyConsult
   */
  public async setState(energyConsult: EnergyConsult) {
    const result = await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${energyConsult.id}
          set: {
            requestStateId: ${await this.getEnergyConsultStateId(energyConsult)}
            changes: ${this.graphqlService.formatChangesObject(energyConsult)}
          }
        }) {
          value {
            id
          }
          messages{
            message
          }
        }
      }`,
    );
    return result.data.updateEnergyConsult.messages;
  }

  /**
   * Disapproves the given energyConsult
   * @param energyConsult The energyConsult to disapprove
   */
  public async disapproveEnergyConsult(energyConsult: EnergyConsult) {
    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${energyConsult.id}
          set: {
            requestStateId: ${await this.getEnergyConsultStateId(energyConsult)}
            disapprovalReason: ${this.graphqlService.parseObject(energyConsult.disapprovalReason)}
            changes: ${this.graphqlService.formatChangesObject(energyConsult)}
          }
        }) {
          value {
            id
          }
          messages{
            message
          }
        }
      }`,
    );
  }

  /**
   * Marks all energyConsult from the specified resident as pending to be deleted.
   * Note that this method directly deletes those energyConsults that can be deleted without revision by a coordinator.
   * @param residentId The id of the resident whose energyConsults should be deleted (or marked for deletion)
   */
  public async markAllEnergyConsultsAsDeletedForResident(residentId: string) {
    await this.graphqlService.query(
      `mutation {
        update_energyConsults(where: {_and: [{resident_id: {_eq: "${residentId}"}}, {state: {_in: ["Open", "New"]}}]}, _set: {state: "Deleted"}){
          returning {
            id
          }
          messages{
            message
          }
        }
      }`,
    );
    await this.graphqlService.query(
      `mutation {
        update_energyConsults(where: {_and: [{resident_id: {_eq: "${residentId}"}}, {state: {_nin: ["Open", "New", "Deleted"]}}]}, _set: {state: "PendingDeleted"}){
          returning {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
          }
          messages{
            message
          }
        }
      }`,
    );
  }

  /**
   * Deletes a specific energyConsult
   * @param energyConsult The energyConsult
   */
  public async delete(energyConsult: EnergyConsult) {
    await this.graphqlService.query(
      `mutation {
        deleteEnergyConsult(energyConsultId: ${energyConsult.id}) {
          value {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`,
    );
  }

  /**
   * Gets the id of the current energyConsult state
   * @param energyConsult The energyConsult
   * @returns The id of the current energyConsult state
   */
  public async getEnergyConsultStateId(energyConsult: EnergyConsult): Promise<number> {
    const result = await this.graphqlService.query(
      `
      query {
        requestStates {
          value(where: {name: {eq: "${energyConsult.state.name}"}}) {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
            }
          }
        }`,
    );
    return result.data.requestStates.value[0].id;
  }

  // prettier-ignore
  /**
   * Creates a new energy consult
   * @param energyConsult The new energy consult
   * @returns Data of the new energy consult
   */
  public async create(energyConsult: EnergyConsult) {
    return await this.graphqlService.query(
      `mutation{
        addEnergyConsult(energyConsult: {
          email: "${energyConsult.resident?.email}",
          firstName: "${energyConsult.resident?.firstName}",
          lastName: "${energyConsult.resident?.lastName}",
          houseNumber: ${energyConsult.houseNumber},
          houseNumberSuffix: "${energyConsult.houseNumberSuffix}",
          phoneNumber: "${energyConsult.resident?.phoneNumber ? `${energyConsult.resident?.phoneNumber}` : ""}",
          postalCode: "${energyConsult.postalCode}",
          specialtyId: ${energyConsult.specialty.id},
          message: ${JSON.stringify(energyConsult.message)},
          ${energyConsult.extraProperties ? "extraProperties: \"" + JSON.stringify(energyConsult.extraProperties).replaceAll("\"", "\\\"") + "\"" : ""}
        }
        ) {
          value{
            id
          }
          messages{
            message
            number
          }
        }
      }`,
      true
    );
  }

  public async getRequestStates(): Promise<RequestState[]> {
    const res = await this.graphqlService.query(
      `query {
        requestStates {
          value {
            name
            id
          }
          messages {
            message
          }
        }
      }`,
    );
    return res.data["requestStates"]?.value.map((requestState: RequestState) => {
      return { name: requestState.name, id: requestState.id };
    });
  }

  public async getAllConsultsCSV(
    options: Partial<{
      states: number[] | null;
      dateFrom: Date | null;
      dateTo?: Date | null;
      actionsLinkedToEC?: { id: number; aaID: number }[];
    }>,
  ): Promise<string> {
    if (options.dateTo) {
      options.dateTo.setHours(23, 59, 59, 999);
    }

    const res = await this.graphqlService.query(
      `query {
        getConsultsAsCSV (
          ${options.states ? "requestStateIDs: " + this.graphqlService.hasuraArrayObjectCleaner(options.states) : ""}
          ${options.dateFrom ? ', dateFrom: "' + convertDate(options.dateFrom) + '"' : ""}
          ${options.dateTo ? ', dateTo: "' + convertDate(options.dateTo) + '"' : ""}
          ${options.actionsLinkedToEC ? ", actionsLinkedToEC: " + '"' + JSON.stringify(options.actionsLinkedToEC).replaceAll('"', '\\"') + '"' : ""}
        ) {
            value {
              content
              fileName
            }
            messages {
              message
            }
          }
        }`,
    );
    return res.data["getConsultsAsCSV"]?.value?.content != null ? res.data["getConsultsAsCSV"]?.value?.content : "null";
  }

  public async getConsultsByCoachByDay(coachIds: number[], date: Date): Promise<EnergyConsult[]> {
    const UTCDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayBefore = new Date(UTCDate);
    const dayAfter = new Date(UTCDate);
    dayAfter.setUTCDate(dayAfter.getUTCDate() + 1);
    const coachIdsString = coachIds.join(", ");
    const where = `(
        where:
          {
            and: [
              { coachId: { in: [${coachIdsString}] }},
              { appointmentDate: { gt: "${dayBefore.toISOString()}" }},
              { appointmentDate: { lt: "${dayAfter.toISOString()}" }}
            ]
          }
      )`;
    const consults = await this.getEnergyConsultData(where);
    return await this.getEnergyConsults(consults);
  }
}
