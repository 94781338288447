import { Injectable, inject } from "@angular/core";
import { EnergyConsult } from "../classes/flow/request/EnergyConsult";
import { FilterGroup } from "../components/badge-filters/badge-filters";
import { EnergyConsultService } from "./energy-consult.service";
import { SnackbarService } from "./snackbar.service";
import { StorageService } from "./storage.service";
import { UtilitiesService } from "./utilities.service";

@Injectable({
  providedIn: "root",
})
export class DashboardDataService {
  private energyConsultService: EnergyConsultService;
  private snackService: SnackbarService;
  private utilityService: UtilitiesService;
  private storageService: StorageService;

  public allConsults: EnergyConsult[]; //all fetched consults, not filtered on coach id (toggle show other coaches consults feature)
  public consults: EnergyConsult[]; //all allconsults destined for user (coach filtered)
  public filteredConsults: EnergyConsult[]; //consults based on active filters and sorting state

  public filterGroups: FilterGroup<EnergyConsult>[];
  public state: "unfetched" | "fetched";

  constructor() {
    this.energyConsultService = inject(EnergyConsultService);
    this.snackService = inject(SnackbarService);
    this.utilityService = inject(UtilitiesService);
    this.storageService = inject(StorageService);

    this.allConsults = [];
    this.consults = [];
    this.filteredConsults = [];

    this.filterGroups = [];
    this.state = "unfetched";
  }

  public updateSingleEnergyConsult(consult: EnergyConsult) {
    console.warn("UPDATING", consult);
    this.allConsults[this.allConsults.findIndex((ec) => ec.id === consult.id)] = consult;
    this.consults[this.consults.findIndex((ec) => ec.id === consult.id)] = consult;
  }

  public async refresh(year: number | null) {
    await this.fetchConsults(year);
  }

  private async fetchConsults(year: number | null) {
    try {
      const res = await this.energyConsultService.load(year);
      this.filteredConsults = this.utilityService.filterRequests(
        this.filterGroups,
        (this.consults = this.allConsults = this.energyConsultService.filterRequestsByAllowedSpecialties(res)),
      );
      this.state = "fetched";
    } catch (e) {
      this.snackService.error();
    }
  }
}
