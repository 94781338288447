import { Environment } from "../app/classes/flow/base/Environment";

export const ENVIRONMENT: Environment = {
  PRODUCTION: false,
  ORGANISATION: "Gemeente Emmen Development",
  API_URL: "https://dev.api.coach.emmengeeftenergie.nl/",
  CLIENT_ID: "ee8e7c87-c42a-4d27-afbc-7148fcf0e3cb",
  AUTHORITY: "https://DeEnergieCoachDev.b2clogin.com/DeEnergieCoachDev.onmicrosoft.com/B2C_1_DEV",
  KNOWN_AUTHORITY: "https://DeEnergieCoachDev.b2clogin.com",

  SOCIALS: {
    SLOGAN: "Emmen geeft energie",
    LINKS: {
      MAIN: "https://emmengeeftenergie.nl/",
      PRIVACY: "https://www.emmengeeftenergie.nl/Beheer/Privacyverklaring/default.aspx",
      DISCLAIMER: "",
    },
  },

  EXTRA_TRANSLATIONS_SUFFIX: [],
  MODULES: ["EMPTY_PDF", "TIME_SLOTS"],
  ALLOWED_SPECIALTIES: [1],
  AREA_OF_OPERATIONS: {
    MATCHES: [],
  },
  CUSTOM_HEADER_LOGOS: [],
};
