import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Header, Link } from "./Header";

@Component({
  selector: "app-table[id][headers][data]",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.less"],
})
/**
 * This component allows for representing data entities as rows.
 * It is possible to enable filtering and alter the content of the columns to be displayed.
 */
export class TableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator)
  public paginator?: MatPaginator;

  @Input("id")
  public id!: string;

  @Input("data")
  public set dataSetter(value: unknown[]) {
    // If there is no data, that means we are setting it for the first time.
    // Skip this first time because the real data has yet to be fetched.
    if (!this.dataSource?.data) {
      this.dataSource = new MatTableDataSource<unknown>([]);
      return;
    }
    this.data = value;
    if (this.dataSource.paginator || !this.pageSizes) this.dataSource.data = value;

    this.update();
  }

  public data?: unknown[];

  @Input("headers")
  public headers!: Header[];

  @Input("search")
  public search?: boolean;

  @Input("customFilter")
  public customFilter?: (data: unknown, filter: string) => boolean;

  @Input("storageId")
  public storageId?: string;

  @Input("pageSizes")
  /**
  public
   * Define this to enable pagination with the given pageSizes
   */
    pageSizes?: number[];

  @ViewChild(MatSort) sort!: MatSort;

  public loading = false;
  public mobileTable = false;

  public searchTerm?: FormControl;

  public dataSource: MatTableDataSource<unknown> = new MatTableDataSource<unknown>([]);

  public get visibleHeaders(): string[] {
    const headers: string[] = [];

    if (this.mobileTable) {
      headers.push(this.headers[0].visualName);
    } else {
      headers.push(...this.headers.filter((h) => !h.hidden).map((h) => h.visualName));
    }
    return headers;
  }

  public ngOnInit() {
    this.showMobileTable();

    if (this.search) {
      this.initializeSearchFunctionality();
    }
    if (this.customFilter) {
      this.dataSource.filterPredicate = this.customFilter;
    }
    this.dataSource.sortingDataAccessor = (item: any, property: any) => {
      const header = this.headers.find((h) => h.visualName === property);
      if (!header) return;

      if (header.sorting?.customSortFunction) {
        return header.sorting.customSortFunction(item);
      }
      if (header.resolver) {
        return header.resolver(item).toLowerCase();
      }
      if (header.mappedTo) {
        return item[header.mappedTo];
      }
    };
  }

  public ngAfterViewInit() {
    if (this.dataSource) this.dataSource.data = this.data ?? [];
    if (this.dataSource) this.dataSource.paginator = this.paginator!;
    if (this.sort) this.dataSource.sort = this.sort;

    if (this.storageId) {
      setTimeout(() => {
        this.sortTable();
      }, 0);
    }
  }

  public sortTable() {
    const sort = localStorage.getItem(`table-sort-${this.id}-${this.storageId}`);
    const pageSize = localStorage.getItem(`table-page-size-${this.id}-${this.storageId}`);
    if (sort && this.sort) {
      const parsedSort = JSON.parse(sort);
      this.sort.sort({ id: parsedSort.active, start: parsedSort.direction, disableClear: false });
    }
    if (pageSize && this.paginator) {
      const parsedPageSize = parseInt(pageSize);
      this.paginator.pageSize = parsedPageSize;
    }
  }

  /**
   * Updates the displayed data in the table by refiltering.
   */
  public update() {
    this.loading = true;
    this.filter();
    this.loading = false;
  }

  public reload() {
    this.dataSource.filter = this.searchTerm?.value.toString() as string;
  }

  public showMobileTable() {
    if (window.outerWidth > 600) {
      this.mobileTable = false;
    } else {
      this.mobileTable = true;
    }
  }

  public onSortChange(e: Sort): void {
    if (this.storageId) {
      localStorage.setItem(`table-sort-${this.id}-${this.storageId}`, JSON.stringify(e));
    }
  }
  public onPageSizeChange(e: PageEvent): void {
    if (this.storageId) {
      localStorage.setItem(`table-page-size-${this.id}-${this.storageId}`, e.pageSize.toString());
    }
  }

  /**
   * Makes a full link where each property of the given link is concatenated as parameter for the link.
   * For example, when a link has a property id, the value of element.id is added as parameter to the link.
   * @param link The Link to base tje url to build of
   * @param element The item containing the properties that are neeeded as parameters
   * @returns A string containing the full url link with parameters
   */
  public makeFullLink(link: Link, element: any): string {
    return link.baseUrl + "/" + link.params.map((p) => element[p]).join("/");
  }

  /**
   * Stores only those entries in filteredData that satisfy the search term
   */
  private filter() {
    if (this.dataSource && this.searchTerm) {
      if (!this.customFilter) {
        this.dataSource.filter = (this.searchTerm?.value.toString() as string).toLowerCase();
      } else {
        this.dataSource.filter =
          (this.searchTerm?.value.toString() as string).toLowerCase() !== "" ? (this.searchTerm?.value.toString() as string).toLowerCase() : "emptyValueCheck";
      }
      this.dataSource.paginator?.firstPage();
    }
  }

  /**
   * Adds search functionality to the table, which only shows rows that contain the search term
   */
  private initializeSearchFunctionality() {
    this.searchTerm = new FormControl("");
    this.searchTerm.valueChanges.subscribe(() => this.update());
  }
}
